<template>
  <div class="novelDetailsPage" id="novels" @scroll="handleScroll">
   <div class="head" :class="isScrolled ? 'headBarBg' : ''">
    <!--               <img class="headBg" src="@/assets/png/novelBg.jpg" />-->
    <div class="headBar">
     <div class="leftIcon" @click="$router.go(-1)">
      <svg-icon class="navArrow" iconClass="navArrow" v-show="isScrolled" />
      <img class="icon" src="@/assets/png/leftH.png" v-show="!isScrolled" />
     </div>
     <div class="statusBtn flex-aic">
     <!-- 点赞 -->
     <div class="btnGroup">
      <div
        class="collectionBtn"
        v-if="atlasData && !atlasData.mediaStatus.hasLiked"
        @click="doLike"
      >
       <svg-icon class="collect" iconClass="likes" />
      </div>
      <div
        class="collectionBtn"
        v-else-if="atlasData && atlasData.mediaStatus.hasLiked"
        @click="doUnlike"
      >
       <svg-icon class="collect" iconClass="hasLike" />
      </div>
     </div>
     <!-- 收藏 -->
     <div class="btnGroup">
      <div
        class="collectionBtn"
        v-if="atlasData && !atlasData.mediaStatus.hasCollected"
        @click="collectionWorks"
      >
       <!--                <img src="@/assets/png/isnovelCollect.png" />-->
       <svg-icon class="collect" iconClass="isnovelCollect" />
      </div>
      <div
        class="collectionBtn"
        v-else-if="atlasData"
        @click="cancelCollectionWorks"
      >
       <!--                <img src="@/assets/png/hasnovelCollect.png" />-->
       <svg-icon class="collect" iconClass="hasnovelCollect" />
      </div>
     </div>
     </div>
    </div>
   </div>
    <PullRefresh
      v-if="!isLoading"
      :disabled="true"
      :is-higeht-max="true"
      :adv-list="advList"
      :hasAdvPagination="true"
      className="NovelDetailsPage"
    >
      <div class="content" v-if="!isLoading && atlasData">
<!--        <img class="headBg" src="@/assets/png/novelBg.png" />-->
        <ImgDecypt
          class="headBg"
          :src="atlasData.verticalCover"
          :key="atlasData.verticalCover"
        ></ImgDecypt>
        <div class="worksInfo">
          <div class="contentInfo">
<!--            <div class="previewImg">-->
<!--              <ImgDecypt-->
<!--                :src="atlasData.verticalCover"-->
<!--                :key="atlasData.verticalCover"-->
<!--              ></ImgDecypt>-->
<!--            </div>-->
            <div class="worksContent">
              <div class="worksTitle">
                <span>{{ atlasData.title }}</span>
              </div>
              <div class="worksTag">
                <div
                  class="tagItem"
                  v-for="(item, index) in atlasData.tagDetails"
                  :key="index"
                  @click.stop="tagJump(index, item)"
                >
                  <svg-icon class="tagPrefix" icon-class="tagPrefix" />
                  <span>{{ item.name }}</span>
                </div>
              </div>
              <div class="worksDetails">
                <span>{{ atlasData.summary || "暂无简介" }}</span>
              </div>

              <!-- <TextOverflow
                class="worksDetails"
                :text="atlasData.summary"
                :maxLines="2.4"
              >
                <template
                  v-slot:default="{ clickToggle, expanded }"
                  v-if="kind == 4 || kind == 1"
                >
                  <div @click.stop="clickToggle" class="text-btn">
                    {{ expanded ? "收起" : "全文" }}
                  </div>
                </template>
              </TextOverflow> -->

              <div class="worksFullBtn" v-if="atlasData.summary.length > 58">
                <div class="showAll">
                  <span @click="introductionShow = true">全文</span>
                </div>
              </div>
            </div>
          </div>
        </div>
<!--        <div class="lines"></div>-->

        <div class="directory">
          <div class="directoryBar">
            <div class="directoryBarTitle">
              <span>选集</span>
            </div>
            <div class="directoryBtn" @click="directoryShow = true">
              <span v-if="kind == 4">全{{ atlasData.contents.length }}章</span>
              <span v-else>全{{ atlasData.contents.length }}集</span>
              <!--                       <img src="@/assets/png/rightDown.png">-->
            </div>
          </div>
          <div class="anthology oepnX" id="anthologyID">
            <div
              :class="`anthologyItem-${index}`"
              v-for="(item, index) in atlasData.contents"
              @click="anthology(item, index)"
              :ref="`item-${index}`"
              :key="index"
            >
              <div class="anthologyItem" :class="{ activeItem: activeIndex == index }">

                <div
                  class="anthologyTxt"
                  :class="{ activeItem: activeIndex == index }"
                >
                  <span>
                    {{ item.episodeNumber }}
                  </span>
                </div>
                <!-- vip -->
                <div class="vipLabel" v-if="item.listenPermission == 0">
<!--                 <span>VIP</span>-->
                  <img src="@/assets/png/vipIcon.png" alt="">
                </div>

                <!-- 金豆 -->
                <div
                  class="goldenLabel"
                  v-if="item.listenPermission == 1 && price > 0"
                >
<!--                 <span>金豆</span>-->
                 <img src="@/assets/png/GoldenIcon.png" alt="">
                </div>

                <!-- 免费 -->
                <div class="freeLabel" v-if="item.listenPermission == 2">
<!--                 <span>免费</span>-->
                 <img src="@/assets/png/freeIcon.png" alt="">
                </div>
                <!-- <div class="activePlay" v-if="activeIndex == index">
                                    <img src="@/assets/gif/activePlay.gif">
                                </div> -->
              </div>
            </div>
          </div>
          <div
            class="startReading"
            @click="anthology(atlasData.contents[0], 0)"
            v-if="activeIndex == 0"
          >
            <div class="startReadingBtn">
              <span v-if="kind == 4">阅读小说</span>
              <span v-else>立即观看</span>
            </div>
          </div>
          <div
            class="startReading"
            @click="anthology(atlasData.contents[activeIndex], activeIndex)"
            v-else
          >
            <div class="startReadingBtn">
              <span v-if="kind == 4">续看第 {{ activeIndex + 1 }} 章</span>
              <span v-else>续看第 {{ activeIndex + 1 }} 集</span>
            </div>
          </div>
<!--          <div class="lines"></div>-->
        </div>

        <!-- 同类作品 -->
        <div class="recommendVideo">
          <div class="recommendTitle">
            <span>大家正在看</span>
          </div>
          <div>
            <VerticleThreeList :videoList="randomList" :isAcg="true"></VerticleThreeList>
          </div>
        </div>

        <div class="separate"></div>

        <div class="comments">
          <div class="commentsContent">
            <div class="commentsHead">
              <span>评论</span>
<!--              <span class="comTotle">{{ msgTotle }}</span>-->
            </div>
            <div class="qiuckSearchBox flex-aic" v-if="quickSearchObj.title">
             <span>大家都在搜索：</span>
             <van-notice-bar
               v-if="quickSearchObj.title"
               @click.stop="jumpAdv(quickSearchObj)"
               color="#F92B6C"
               background="transparent"
               scrollable
               :text="quickSearchObj.title"
             />
            </div>
            <div class="commentsContentList" v-if="commentList.length > 0">
              <div
                class="commentBox"
                v-for="item in commentList"
                :key="item.id"
              >
                <div class="commentInfo">
                  <ImgDecypt
                    class="avatar"
                    round
                    :src="item.userPortrait"
                    :key="item.userPortrait"
                  />
                  <div class="info">
                    <div class="name">
                      <span>{{ item.userName }}</span>
                      <img class="hotImg" v-if="item.isHotComment" src="@/assets/png/isHotComment.png" />
                      <img class="hotImg" v-if="item.isTop" src="@/assets/png/commentTop.png" />
<!--                     <svg-icon-->
<!--                        :iconClass="-->
<!--                          item.gender == 'male' ? 'maleMsg' : 'female'-->
<!--                        "-->
<!--                      ></svg-icon>-->
                    </div>
                    <div class="text msgContent" v-if="!item.isDelete">
                      <div style="width: 100%">
                          <CommentJump
                              class="commit_content"
                              :text="item.content"
                              :keyword="item.searchKeyword"
                              :linkType="item.linkType"
                              :linkStr="item.linkStr"
                          />
                      </div>
                    </div>
                    <div class="text" style="color: rgb(153, 153, 153)" v-else>
                      内容已被删除
                    </div>
                    <div class="address" v-if="!item.isTop && !item.isOfficial">
                      <div class="createdAt">
                        <span>{{ formatDate(item.createdAt) }}</span>
                      </div>

                      <div class="btnBox" v-if="!item.linkType">
                        <span @click.stop="reply(item, null)">
                          <svg-icon
                            class="community_info"
                            iconClass="community_info"
                          />
                        </span>

                        <span @click.stop="likeComment(item)">
                          <svg-icon
                            class="like icon"
                            v-if="item.isLike"
                            iconClass="postLikeedIcon"
                          />
                          <svg-icon
                            class="like icon"
                            v-else
                            iconClass="postLikeIcon"
                          />
                        </span>
                      </div>
                    </div>
<!--                    <div v-else>-->
<!--                      <span style="color: rgb(153, 153, 153); font-size: 12px"-->
<!--                        >&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;此评论系统生成 无法回复</span-->
<!--                      >-->
<!--                    </div>-->

                    <div class="commentBox" style="border-bottom: none">
                      <div
                        class="commentInfo"
                        v-if="item.Info && item.Info.length > 0"
                        style="margin-bottom: 10px"
                      >
                        <ImgDecypt
                          class="avatar"
                          round
                          :src="item.Info[0].userPortrait"
                          :key="item.Info[0].userPortrait"
                        />
                        <div class="childInfo">
                          <div class="name">
                            <span>{{ item.Info[0].userName }}</span>
<!--                            <svg-icon-->
<!--                              :iconClass="-->
<!--                                item.Info[0].gender == 'male'-->
<!--                                  ? 'male'-->
<!--                                  : 'female'-->
<!--                              "-->
<!--                            ></svg-icon>-->
                            <svg-icon
                              v-if="
                                item.Info[0].toUserID != 0 &&
                                item.Info[0].toUserID != null
                              "
                              class="rightCommIcon"
                              iconClass="rightCommIcon"
                            ></svg-icon>
                            <span v-if="item.Info[0].toUserID">{{
                              item.Info[0].toUserName
                            }}</span>
                          </div>
                          <div
                            class="text msgContent"
                            v-if="!item.Info[0].isDelete"
                          >
                            <div style="width: 100%">
                                <CommentJump
                                    class="commit_content"
                                    :text="item.Info[0].content"
                                    :keyword="item.Info[0].searchKeyword"
                                    :linkType="item.Info[0].linkType"
                                    :linkStr="item.Info[0].linkStr"
                                />
                            </div>
                          </div>
                          <div
                            class="text"
                            style="color: rgb(153, 153, 153)"
                            v-else
                          >
                            内容已被删除
                          </div>
                          <div class="address">
                            <div class="createdAt">
                              <span>{{ formatDate(item.Info[0].createdAt) }}</span>
                            </div>

                            <div class="btnBox" v-if="!item.Info[0].linkType">
                              <span @click.stop="reply(item.Info[0], item)">
                                <svg-icon
                                  class="community_info"
                                  iconClass="community_info"
                                />
                              </span>

                              <span @click.stop="likeComment(item.Info[0])">
                                <svg-icon
                                  class="like icon"
                                  v-if="item.Info[0].isLike"
                                  iconClass="postLikeedIcon"
                                />
                                <svg-icon
                                  class="like icon"
                                  v-else
                                  iconClass="postLikeIcon"
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="item.isShow">
                        <div
                          class="commentInfo"
                          v-for="subComment in item.Info.slice(1)"
                          :key="subComment.id"
                          style="margin-bottom: 10px"
                        >
                          <ImgDecypt
                            class="avatar"
                            round
                            :src="subComment.userPortrait"
                            :key="item.userPortrait"
                          />
                          <div class="childInfo">
                            <div class="name">
                              <span>{{ subComment.userName }}</span>
<!--                              <svg-icon-->
<!--                                :iconClass="-->
<!--                                  subComment.gender == 'male'-->
<!--                                    ? 'male'-->
<!--                                    : 'female'-->
<!--                                "-->
<!--                              ></svg-icon>-->
                              <svg-icon
                                v-if="
                                  subComment.toUserID != 0 &&
                                  subComment.toUserID != null
                                "
                                class="rightCommIcon"
                                iconClass="rightCommIcon"
                              ></svg-icon>
                              <span
                                v-if="
                                  subComment.toUserID != 0 &&
                                  subComment.toUserID != null
                                "
                                >{{ subComment.toUserName }}</span
                              >
                            </div>
                            <div
                              class="text msgContent"
                              v-if="!subComment.isDelete"
                            >
                              <div style="width: 100%">
                                  <CommentJump
                                      class="commit_content"
                                      :text="subComment.content"
                                      :keyword="subComment.searchKeyword"
                                      :linkType="subComment.linkType"
                                      :linkStr="subComment.linkStr"
                                  />
                              </div>
                            </div>
                            <div
                              class="text"
                              style="color: rgb(153, 153, 153)"
                              v-else
                            >
                              内容已被删除
                            </div>
                            <div class="address">
                              <div class="createdAt">
                                <span>{{ formatDate(subComment.createdAt) }}</span>
                              </div>

                              <div class="btnBox" v-if="!subComment.linkType">
                                <span @click.stop="reply(subComment, item)">
                                  <svg-icon
                                    class="community_info"
                                    iconClass="community_info"
                                  />
                                </span>

                                <span @click.stop="likeComment(subComment)">
                                  <svg-icon
                                    class="like icon"
                                    v-if="subComment.isLike"
                                    iconClass="postLikeedIcon"
                                  />
                                  <svg-icon
                                    class="like icon"
                                    v-else
                                    iconClass="postLikeIcon"
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <Loading
                        class="sec-loading"
                        v-if="
                          commentParams.currentFirstItem.id == item.id &&
                          commentParams.currentLoading
                        "
                      />
                      <div
                        class="load-more"
                        v-else-if="
                          !commentParams.currentLoading &&
                          commentParams.currentFirstItem.id == item.id &&
                          commentParams.currentHasNext
                        "
                        @click.stop="loadMoreSubComment(item)"
                      >
                        — 点击加载更多评论
                      </div>
                    </div>
                  </div>
                  <img
                    class="godMsg"
                    v-if="item.isGodComment"
                    src="@/assets/png/godMsg.png"
                  />
                </div>
                <div
                  class="expand-box"
                  @click.stop="onShow(item)"
                  v-if="item.commCount && item.commCount > 1"
                >
                  {{
                    item.isShow ? "— 收起" : `— 展开${item.commCount - 1}条回复`
                  }}
                  <svg-icon
                    iconClass="expand"
                    :class="[
                      { 'rotate-target': item.isShow },
                      { 'rotate-burden-target': !item.isShow },
                    ]"
                  />
                </div>
              </div>
            </div>
            <div class="nodata" v-else>空空如也～</div>
          </div>
        </div>
      </div>
    </PullRefresh>
    <Loading v-else />

    <!-- <div class="tabBar" @click="showInput">
      <div class="comments">
        <span>发条有爱的评论吧</span>
        <img src="@/assets/png/commentsEditor.png" />
      </div>
      <div class="placeholder"></div>
    </div> -->
    <div class="tabBars">
      <div class="comments">
        <van-field
          class="Inputs"
          ref="commentInputBoxRef"
          v-model="commentParams.content"
          :border="false"
          :placeholder="placeholder"
          @blur="changBlur"
        />
        <div
          class="sendBtn"
          :class="{ activeBtn: msgVal }"
          @mousedown="handleMousedown"
        >
          <img src="@/assets/png/sendMsg.png" />
        </div>
      </div>
    </div>
    <!-- 简介弹窗 -->
    <van-popup
      v-model="introductionShow"
      position="bottom"
      :style="{ height: '511px', background: 'rgb(255,255,255)', borderRadius: '18px 18px 0 0' }"
    >
      <div class="introductionContent" v-if="atlasData">
        <div class="introductionBar">
          <div class="title">
            <span>简介</span>
          </div>
<!--          <div class="btn" @click="introductionShow = false">-->
<!--            <img src="@/assets/png/popupClose.png" />-->
<!--          </div>-->
        </div>
        <div class="introductionTitle">
          <span>{{ atlasData.title }}</span>
        </div>
        <div class="introductionTag">
          <span class="tagItem" v-for="(item, index) in atlasData.tagDetails" :key="index">
            <svg-icon class="tagPrefix" icon-class="tagPrefix" />
            <span>{{ item.name }}</span>
          </span>
        </div>
        <div class="introductionDetails">
          <span>{{ atlasData.summary }}</span>
        </div>
      </div>
    </van-popup>

    <!-- 目录弹窗 -->
    <van-popup
      v-model="directoryShow"
      position="bottom"
      :style="{ height: '611px', background: '#F5F5F5', borderRadius: '18px 18px 0 0' }"
    >
      <div class="directoryContent" v-if="atlasData">
        <div class="directoryBar">
          <div class="title">
            <span v-if="kind == 4"
              >目录（全{{ atlasData.contents2.length }}章）</span
            >
            <span v-if="kind == 1"
              >目录（全{{ atlasData.contents2.length }}话）</span
            >
          </div>
          <div class="btn" @click="chapterSort">
            <span v-if="sorting">倒序</span>
            <span v-else>升序</span>
          </div>
          <!-- <div class="btn" @click="directoryShow = false">
            <img src="@/assets/png/popupClose.png" />
          </div> -->
        </div>
        <div class="anthologyContentBox">
         <div class="anthologyClassifyBox" v-if="atlasData.contents2.length >= 30">
          <div @click="toggleAnthologyCompilation(key)" class="anthologyClassifyItem" :class="anthologyClassifyActiveIndex === key?'anthologyClassifyActiveItem':''" v-for="key in Math.ceil(atlasData.contents2.length / 30)" :key="key">
           <span>{{((key - 1)) * 30 + 1}}~{{key * 30}}</span>
          </div>
         </div>

         <div class="anthologyContent">
          <div @click="toggleAnthologyCollect(item, item.originalIndex)" v-for="(item) in sortedContent" :key="item.originalIndex">

           <div class="anthologyItem" :class="{ activeItem: activeIndex2 == item.originalIndex }">

            <div
              class="anthologyTxt"
              :class="{ activeItem: activeIndex2 == item.originalIndex }"
            >
<!--             <span>{{index + 1 + ((anthologyClassifyActiveIndex - 1) * 30)}}</span>-->
              <span>
                {{ item.episodeNumber }}
              </span>
            </div>

            <div class="vipLabel" v-if="item.listenPermission === 0">
             <img src="@/assets/png/vipIcon.png" alt="">
            </div>

            <div class="vipLabel" v-if="item.listenPermission === 1">
             <img src="@/assets/png/GoldenIcon.png" alt="">
            </div>

            <div class="vipLabel" v-if="item.listenPermission === 2">
             <img src="@/assets/png/freeIcon.png" alt="">
            </div>

           </div>

          </div>
         </div>

<!--          <div-->
<!--            v-for="(item, index) in atlasData.contents2"-->
<!--            @click="-->
<!--              anthology(-->
<!--                item,-->
<!--                sorting ? index : atlasData.contents2.length - index - 1-->
<!--              )-->
<!--            "-->
<!--            :key="index"-->
<!--          >-->
<!--           <div class="anthologyItem" :class="{ activeItem: activeIndex2 == index }">-->

<!--            <div-->
<!--              class="anthologyTxt"-->
<!--              :class="{ activeItem: activeIndex2 == index }"-->
<!--            >-->
<!--                  <span>-->
<!--                    {{ item.episodeNumber }}-->
<!--                  </span>-->
<!--            </div>-->
<!--            &lt;!&ndash; vip &ndash;&gt;-->
<!--            <div class="vipLabel" v-if="item.listenPermission == 0">-->
<!--             &lt;!&ndash;                 <span>VIP</span>&ndash;&gt;-->
<!--             <img src="@/assets/png/vipIcon.png" alt="">-->
<!--            </div>-->

<!--            &lt;!&ndash; 金豆 &ndash;&gt;-->
<!--            <div-->
<!--              class="goldenLabel"-->
<!--              v-if="item.listenPermission == 1 && price > 0"-->
<!--            >-->
<!--             &lt;!&ndash;                 <span>金豆</span>&ndash;&gt;-->
<!--             <img src="@/assets/png/GoldenIcon.png" alt="">-->
<!--            </div>-->

<!--            &lt;!&ndash; 免费 &ndash;&gt;-->
<!--            <div class="freeLabel" v-if="item.listenPermission == 2">-->
<!--             &lt;!&ndash;                 <span>免费</span>&ndash;&gt;-->
<!--             <img src="@/assets/png/freeIcon.png" alt="">-->
<!--            </div>-->
<!--            &lt;!&ndash; <div class="activePlay" v-if="activeIndex == index">-->
<!--                                <img src="@/assets/gif/activePlay.gif">-->
<!--                            </div> &ndash;&gt;-->
<!--           </div>-->
<!--          </div>-->
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import TextOverflow from "@/components/TextOverflow";
import { mapGetters } from "vuex";

import { Toast } from "vant";
import {
  sendComment,
  sendV2Comment,
  thumbsDownComment,
  thumbsUpComment,
} from "@/api/mine";
import {
 doCollect, doLike, doUnlike,
 douUcollect, doVideoLike, doVideoUnlike,
 queryMediaInfoList,
 queryRandomInfoList,
} from "@/api/video";
import ImgDecypt from "@/components/ImgDecypt";
import Loading from "@/components/Loading";
import VerticleThreeList from "@/components/VideoList/VerticleThreeList";
import PullRefresh from "@/components/PullRefresh";
import CommentJump from "@/components/CommentJump";
import { setSessionItem } from "@/utils/longStorage";
import {addVideoHistory, formatDate, queryHistoryId} from "@/utils";
import { getBeijinTime } from "@/utils";
import { queryCommentListlever2 } from "@/api/user";

import store from "@/store";
import { queryCommentList } from "@/api/user";
import headBar from "@/components/HeadBar/index.vue";
import {jumpAdv} from "@/utils/getConfig";
export default {
  name: "NovelDetailsPage",
 computed: {
  ...mapGetters({
   buyStatus: "buyStatus",
   userInfo: 'userInfo'
  }),
  headBar() {
   return headBar
  },
  sortedContent() {
   let start = (this.anthologyClassifyActiveIndex - 1) * 30;
   let end = this.anthologyClassifyActiveIndex * 30;
   let currentContents = this.atlasData.contents2.slice(start, end);

   currentContents = currentContents.map((item, index) => {
    return {
     ...item,
     originalIndex: item.originalIndex !== undefined ? item.originalIndex : start + index
    };
   });

   if (!this.sorting) {
    return currentContents.reverse();
   } else {
    return currentContents;
   }
  }
 },
  components: {
    ImgDecypt,
    Loading,
    VerticleThreeList,
    PullRefresh,
    CommentJump,
    // TextOverflow
  },
  data() {
    return {
      maxEpisode: 0, // 用于存储最大集数
      mediaID: null,
      kind: null,
      atlasData: null,
      introductionShow: false,
      directoryShow: false,
      isLoading: true,
      loading: true,
      refreshing: false,
      randomList: [],
      finished: false,
      isNoData: false,
      error: false,
      advList: [],
      activeIndex: 0,
      activeIndex2: 0,
      commentList: [],
      pageNumber: 1,
      pageSize: 10,
      isShowInput: false,
      msgVal: "",
      placeholder: "我怀疑你也想开车",
      cid: undefined,
      userId: undefined,
      level: 1,
      msgTotle: 0,
      price: 0,
      sorting: true,
      currMsgItem: "",
      commentParams: {
        objID: "", // 帖子ID,
        level: 1, // 评论级别，1: 一级评论;2: 二级评论
        content: "", // 评论内容
        cid: "", // 一级评论的ID
        userID: "", // 一级评论的用户ID
        currentFirstItem: {}, // 当前一级评论对象
        currentHasNext: false, // 当前评论是否还有下一页数据
        currentLoading: false, // 当前二级评论的loading
        currentShowInputId: "", // 当前显示的输入框id
        rid: "",
        toUserID: "",
      }, // 评论参数
      secPageNumber: 1, // 二级评论页码
      secPageSize: 10, // 二级评论一页的数量
      quickSearchObj: {}, // 大家都在搜对象
      isScrolled: false, // 显示tab
      anthologyClassifyActiveIndex: 1,
      scrollTop: 0,
      touchStartX: 0,
      touchEndX: 0,
    };
  },
  mounted() {
    let query = this.$route.query;
    if (query) {
      this.mediaID = query.id;
      this.kind = query.kind;
      this.price = query.price ? Number(query.price) : 0;
      this.activeIndex = 0;
      this.activeIndex2 = 0;
      this.isLoading = true;
      this.getMediaInfoList();
      this.getRandomInfoList();
      this.getCommentList();
    }
    window.addEventListener("scroll", this.handleScroll, true);
    window.addEventListener('touchstart', this.onTouchStart);
    window.addEventListener('touchend', this.onTouchEnd);
    window.jumpKeywords = this.jumpKeywords;
  },
  activated() {
    const { refreshFlag } = store.state.pageRefresh;
    let query = this.$route.query;
    if (query && refreshFlag.novelDetailsPage) {
      this.resetData(query);
    }
    if (!refreshFlag.novelDetailsPage) {
      this.setActiveIndex();
    }
  },
  deactivated() {
    store.commit("pageRefresh/SET_REFRESHFLAG", {
      name: "novelDetailsPage",
      flag: false,
    });
    this.atlasData.historyIndex = this.activeIndex;
    addVideoHistory(this.atlasData);
  },
  watch: {
    $route(to, from) {
      const { id } = to.query;
      const novelDetailsPageId = sessionStorage.getItem("novelDetailsPageId");
      if (id && novelDetailsPageId && id !== novelDetailsPageId) {
        this.resetData(to.query);
      }
    },
  },
  methods: {
   jumpAdv,
   formatDate,
    jumpKeywords(str) {
     const strArr = str.split(',');
     const link = strArr[0];
     const id = strArr[1];
     let obj = {
      link: link,
      id: id,
     };
     jumpAdv(obj);
    },
    onTouchStart(event) {
     this.touchStartX = event.touches[0].clientX;
    },
    onTouchEnd(event) {
     this.touchEndX = event.changedTouches[0].clientX;
     this.handleGesture();
    },
    handleGesture() {
     if (this.touchEndX < this.touchStartX) {
      const distance = this.touchEndX - this.touchStartX;
      if (distance > 50) {
       alert(11111);
       this.$router.go(-1);
      }
     }
    },
    toggleAnthologyCompilation(index){
     let activeIndex = this.activeIndex2;
     let newStart = (index - 1) * 30;

     let newEnd = index * 30;

     let currentContents = this.atlasData.contents2.slice(newStart, newEnd);

     let existingItem = currentContents.find(item => item.originalIndex === this.activeIndex2);

     if (existingItem) {
      this.activeIndex2 = currentContents.length > 0 ? currentContents[0].originalIndex : newStart;
     } else {
      this.activeIndex2 = activeIndex;
     }
     this.anthologyClassifyActiveIndex = index;
    },
    toggleAnthologyCollect(item, index){
     this.anthology(item, index);
     console.log(item, index);
    },
    getIndex(index) {
     return !this.sorting ? this.sortedContent.length - 1 - index : index;
    },
    handleScroll(e) {
     this.scrollTop = e.target.scrollTop;
     this.isScrolled = this.scrollTop > 40;
    },
    resetData(query) {
      this.atlasData = null;
      this.mediaID = query.id;
      sessionStorage.setItem("novelDetailsPageId", query.id);
      this.kind = query.kind;
      this.price = query.price ? Number(query.price) : 0;
      this.activeIndex = 0;
      this.isLoading = true;
      this.getMediaInfoList();
      this.getRandomInfoList();
      this.commentList = [];
      this.getCommentList("refresh");
    },
    // 缓存设置级数
    async setActiveIndex() {
      this.directoryShow = false;
      let historyPostInfo = await queryHistoryId(this.atlasData.id);
      this.activeIndex = historyPostInfo.historyIndex || 0;
      this.activeIndex2 = historyPostInfo.historyIndex || 0;
      const element = document.getElementById("anthologyID");
      let targetElement = document.getElementsByClassName(
        `anthologyItem-${this.activeIndex}`
      )[0]; // 选择目标元素
      let rect = targetElement.getBoundingClientRect().left;
      let elementWidth = targetElement.offsetWidth;
      let parentWidth = element.offsetWidth;
      element.scrollLeft = rect + elementWidth / 2 - parentWidth / 2;
      // element.scrollLeft = rect;
    },
    scrolls(index) {
      if (index) {
        this.$nextTick(() => {
          let target = this.$refs[`item-${index}`][0];
          let parent = target.parentNode;
          let parentHalfWidth = parent.offsetWidth / 2;
          let targetHalfWidth = target.offsetWidth / 2;
          parent.scrollLeft =
            target.offsetLeft - parentHalfWidth + targetHalfWidth;
        });
      }
    },
    chapterSort() {
     let activeIndex = this.activeIndex2;
      //章节排序
      this.sorting = !this.sorting;
      // let totalItemsInPage = this.sortedContent.length;
      // this.activeIndex2 = totalItemsInPage - 1 - (this.activeIndex2 % 30);
      let currentPageContents = this.sortedContent;
      let currentItem = currentPageContents.find(item => item.originalIndex === this.activeIndex2);
      if (currentItem) {
       this.activeIndex2 = activeIndex;
      } else {
       this.activeIndex2 = currentPageContents.length > 0 ? currentPageContents[0].originalIndex : (this.anthologyClassifyActiveIndex - 1) * 30;
      }
      // this.atlasData.contents2.reverse();
      // this.activeIndex2 =
      //   this.atlasData.contents2.length - 1 - this.activeIndex2;
      // console.log(this.atlasData.contents2);
    },
    async onShow(comment) {
      console.log(comment);
      if (comment.isShow) {
        this.$set(comment, "isShow", false);
      } else {
        this.commentParams.currentHasNext = true; // 初始化当前评论默认有下一页
        this.commentParams.currentFirstItem = comment;
        this.commentParams.currentLoading = true;
        this.secPageNumber = 1;
        await this.getSecondList(comment);
        this.$set(comment, "isShow", true);
      }
    },
    async getSecondList(comment) {
      /**
       * 获取二级评论
       */
      let req = {
        objID: comment.objID,
        cmtId: comment.id,
        fstID: comment.Info[0].id, // 第一条评论的ID
        curTime: new Date().toISOString(),
        pageNumber: this.secPageNumber,
        pageSize: this.secPageSize,
        objType: 'media',
      };

      try {
        let res = await this.$Api(queryCommentListlever2, req);
        this.commentParams.currentLoading = false;
        if (res.data.list && res.data.list.length > 0) {
          let commentNo = [comment.Info[0]];
          if (this.secPageNumber == 1) {
            comment.Info = commentNo.concat(res.data.list);
          } else {
            comment.Info = commentNo.concat(comment.Info.concat(res.data.list));
          }
        }
        if (!res.data.hasNext) {
          this.commentParams.currentHasNext = false;
        }
      } catch (error) {
        this.error = true;
        this.loading = false;
      }
    },
    changBlur() {
      window.scroll(0, 0); //失焦后强制让页面归位
    },
    async getMediaInfoList() {
      let req = {
        mediaID: this.mediaID,
      };
      let res = await this.$Api(queryMediaInfoList, req);
      const { data } = res;
      console.log(res.data);
      this.isLoading = false;

      if (res && res.code == 200) {
        this.atlasData = data;
        this.atlasData.contents2 = JSON.parse(
          JSON.stringify(this.atlasData.contents)
        );
        this.msgTotle = data.countComment || 0;
        let historyPostInfo = await queryHistoryId(this.atlasData.id);
        if (historyPostInfo) {
          this.maxEpisode =
            this.$store.state.reader.maxEpisodes[this.atlasData.id];
          this.scrolls(this.maxEpisode);
          this.activeIndex = historyPostInfo.historyIndex || 0;
          this.activeIndex2 = historyPostInfo.historyIndex || 0;
        }
       console.log(this.activeIndex, this.activeIndex2, 'indexxxxxxxxxxxxxxx');
       // data.contents2 = [];
        setSessionItem("currentWorks", JSON.stringify(data));
        this.$store.commit("video/SET_CURRENT_WORKS", data);
      }
    },
    async getRandomInfoList() {
      let req = {
        kind: this.kind,
        pageNumber: 1,
        pageSize: 6,
      };
      let res = await this.$Api(queryRandomInfoList, req);
      if (res && res.code == 200) {
        this.randomList = res.data.MediaList;
      }
    },
    anthology(item, index, type) {
      console.log(this.kind);
      let _this = this;
      let hasPaid = item.mediaStatus.hasPaid || this.atlasData.mediaStatus.hasPaid;
     //vip
      if (item.listenPermission == 0 && !hasPaid) {
        if (!_this.$store.getters.isVip) {
          _this.$bus.$emit("vipPopup", {
            state: 1,
            videoType: this.kind,
            videoInfo: this.atlasData,
            id: this.atlasData.id,
            currentEpisode: item,
            closeBtn: () => {
              _this.$bus.$emit("closeVipPopup");
            },
            buySuccess: () => {
              if (this.buyStatus === 1) {
               item.mediaStatus.hasPaid = true;
              } else if (this.buyStatus === null) {
               this.atlasData.mediaStatus.hasPaid = true;
              }
              console.log(this.buyStatus, this.atlasData.mediaStatus.hasPaid, item.mediaStatus.hasPaid);
              // this.atlasData.mediaStatus.hasPaid = true;
              this.$store.commit('movies/SET_BUY_STATUS', null);
              this.$bus.$emit("closeVipPopup");
              this.handleJump(item, index);
            },
          });
          return;
        }
      }
      //金豆收费
      if (item.listenPermission == 1 && !hasPaid) {
       _this.$bus.$emit("vipPopup", {
        id: this.atlasData.id,
        state: this.kind == 4 ? 2 : 4,
        goldenNum: this.price,
        videoType: this.kind,
        videoInfo: this.atlasData,
        currentEpisode: item,
        closeBtn: () => {
         _this.$bus.$emit("closeVipPopup");
        },
        buySuccess: () => {
         if (this.buyStatus === 1) {
          item.mediaStatus.hasPaid = true;
         } else if (this.buyStatus === null || this.kind === 4) {
          this.atlasData.mediaStatus.hasPaid = true;
         }
         console.log(this.buyStatus, this.atlasData.mediaStatus.hasPaid, item.mediaStatus.hasPaid);
         // this.atlasData.hasPaid = true;
         this.$bus.$emit("closeVipPopup");
         this.$store.commit('movies/SET_BUY_STATUS', null);
         this.handleJump(item, index);
        },
       });
       return;
      }
      console.log(this.atlasData, "+++++++++++++++++", index);
      this.handleJump(item, index);
    },
    handleJump(item, index) {
     if (this.kind == 1) {
      this.activeIndex = index;
      this.activeIndex2 = index;
      this.atlasData.historyIndex = this.activeIndex;
      addVideoHistory(this.atlasData);
      this.$router.push({
       path: "/comicReader",
       query: {
        itemIndex: index,
       },
      });
     } else if (this.kind == 4) {
      this.activeIndex = index;
      this.activeIndex2 = index;
      this.atlasData.historyIndex = this.activeIndex;
      addVideoHistory(this.atlasData);
      this.$router.push({
       path: "/reader",
       query: {
        id: item.id,
        itemIndex: index,
       },
      });
     }
    },
    onLoad() {
      this.loading = true;
    },
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.isNoData = false;
    },
    async doLike() {
     let req = {
      kind: this.kind,
      mediaID: this.mediaID,
     };
     let res = await this.$Api(doLike, req);
     if (res && res.code == 200) {
      this.atlasData.mediaStatus.hasLiked = true;
      Toast("点赞成功");
     }
    },
    async doUnlike() {
     let req = {
      kind: this.kind,
      mediaID: this.mediaID,
     };
     let res = await this.$Api(doUnlike, req);
     if (res && res.code == 200) {
      this.atlasData.mediaStatus.hasLiked = false;
      Toast("取消点赞");
     }
    },
    async collectionWorks() {
      /**
       * 收藏作品
       */

      let req = {
        kind: Number(this.kind),
        mediaID: this.mediaID,
      };
      let res = await this.$Api(doCollect, req);

      if (res.code == 200) {
        this.atlasData.mediaStatus.hasCollected = true;
        this.$toast({
          message: "收藏成功",
          position: "top",
        });
      }
    },
    async cancelCollectionWorks() {
      /**
       * 取消收藏
       */
      let req = {
        kind: Number(this.kind),
        mediaID: this.mediaID,
      };
      let res = await this.$Api(douUcollect, req);
      if (res.code == 200) {
        this.atlasData.mediaStatus.hasCollected = false;
        this.$toast({
          message: "取消收藏",
          position: "top",
        });
      }
    },
    // showInput() {
    //     console.log(123123)
    //     this.placeholder = `我怀疑你也想开车`;
    //     this.cid = null;
    //     this.currMsgItem = null;

    //     this.level = 1;
    //     this.isShowInput = true;
    //     this.$nextTick(() => {
    //         if (this.$refs.newInput) {
    //             this.$refs.newInput.focus()
    //         }
    //     })
    // },
    async getCommentList(type) {
      /**
       * 评论列表
       */
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        curTime: new Date().toISOString(),
        objID: this.mediaID,
        objType: 'media',
      };
      let res = await this.$Api(queryCommentList, req);
      this.loading = false;
      this.refreshing = false;
      this.isLoading = false;

      if (res && res.code == 200) {
        if (res.data.list) {
          if (type == "refresh") {
            this.commentList = res.data.list;
          } else {
            this.commentList = this.commentList.concat(res.data.list);
          }
        }
        if (this.pageNumber === 1 && res.data.quickSearchList && res.data.quickSearchList.length) {
         res.data.quickSearchList.map((item) => {
          if (item.type === 2) {
           this.quickSearchObj = item;
          } else {
           // 评论对象
           const _reg = new RegExp(item.searchKeyword, 'g');
           let obj = {
            id: item.id,
            content: item.title.replace(
              _reg,
              `<span style="color:rgba(86, 250, 246, 1)" onclick="jumpKeywords('${item.link},${item.id}')">${item.searchKeyword}</span>`,
            ),
            userName: item.userName,
            userID: item.userID,
            Info: [],
            userPortrait: item.userPortrait,
            isOfficial: true,
           };
           this.commentList.splice(1, 0, obj);
          }
         });
        }
        if (this.pageNumber === 1 && this.commentList.length === 0) {
          this.isNoData = true;
        }
        if (
          !res.data.hasNext ||
          (res.data.list && res.data.list.length < req.pageSize)
        ) {
          this.finished = true;
        }
      }
    },
    jumpUserPage(item) {
      this.$router.replace({
        path: "/userHomePage",
        query: {
          uid: item.userID,
        },
      });
      this.$store.commit("user/SET_COMMENTPOP", {
        show: false,
        info: {},
      });
      if (this.$route.path == "/userHomePage") {
        this.reload();
      }
    },
    showInput() {
      let _this = this;

      /**
       * 加入了判断是否VIP
       */
      if (this.userInfo.isVip && this.userInfo.vipLevel) {
        this.level = 1;
        this.isShowInput = true;
        this.$nextTick(() => {
          if (this.$refs.newInput) {
            this.$refs.newInput.focus();
          }
        });
      } else {
        _this.$bus.$emit("vipPopup", {
          state: 1,
          closeBtn: () => {
            _this.$bus.$emit("closeVipPopup");
          },
        });
      }
    },
    // showInput() {
    //   this.placeholder = `我怀疑你也想开车`;
    //   this.cid = null;
    //   this.currMsgItem = null;

    //   this.level = 1;
    //   this.isShowInput = true;
    //   this.$nextTick(() => {
    //     if (this.$refs.newInput) {
    //       this.$refs.newInput.focus();
    //     }
    //   });
    // },
    closeInput() {
      this.cid = undefined;
      this.userId = undefined;
      this.msgVal = "";
      this.level = 1;
    },
    // 发送时阻止ios默认事件
    async handleMousedown(event) {
      event.preventDefault();
      this.$refs.commentInputBoxRef.blur();
      await this.sendMsg();
    },
    async sendMsg() {
     console.log(this.atlasData.contents[this.activeIndex].id);
     if (!this.commentParams.content) {
        this.$toast("请输入评论");
        return;
      }
      let req = {};
      if (this.commentParams.level == 1) {
        // 一级评论参数
        req = {
          objID: this.mediaID,
          level: 1,
          content: this.commentParams.content,
          objType: "media",
          cid: this.cid,
          sid: this.atlasData.contents[this.activeIndex].id,
        };
      } else {
        // 二级评论参数
        if (this.commentParams.toUserID) {
          req = {
            objID: this.mediaID,
            level: 2,
            content: this.commentParams.content,
            cid: this.commentParams.cid,
            userID: this.commentParams.userID,
            rid: this.commentParams.rid,
            toUserID: this.commentParams.toUserID,
            objType: "media",
           sid: this.atlasData.contents[this.activeIndex].id,
          };
        } else {
          req = {
            objID: this.mediaID,
            level: 2,
            content: this.commentParams.content,
            cid: this.commentParams.cid,
            userID: this.commentParams.userID,
            objType: "media",
            sid: this.atlasData.contents[this.activeIndex].id,
          };
        }
      }
      this.$store.commit("app/SET_LOADING", true);
      try {
        let res = await this.$Api(sendV2Comment, req);
        this.$store.commit("app/SET_LOADING", false);
        this.commentParams.content = ""; // 评论输入框内容清空
        this.lfCount++; // 评论+1
        if (this.commentParams.level == 1) {
          this.isNoData = false;
         if (this.$store.getters.isVip) {
          this.commentList.unshift(res.data);
         }
        } else if (this.commentParams.level == 2) {
          this.commentList = this.commentList.map((item) => {
            if (item.id == this.commentParams.currentFirstItem.id) {
              item.isShow = true;
             if (this.$store.getters.isVip) {
              item.Info.unshift(res.data);
             }
            }
            return item;
          });
        }
        this.clearReply();
        this.$toast("评论成功");
      } catch (error) {
        this.$store.commit("app/SET_LOADING", false);
        Toast("评论失败，请稍后再试");
      }
    },
    clearReply() {
      this.commentParams.currentFirstItem = {};
      this.commentParams.objID = "";
      this.commentParams.cid = "";
      this.commentParams.userID = "";
      this.commentParams.level = 1;
      this.placeholder = `我怀疑你也想开车`;
    },
    // 回复-显示输入框
    replyMsg(item) {
      this.placeholder = `回复 @${item.userName}`;
      this.cid = item.id;
      this.userId = item.userId;
      this.level = 2;
      this.isShowInput = true;
      this.currMsgItem = item;
      this.$nextTick(() => {
        if (this.$refs.newInput) {
          this.$refs.newInput.focus();
        }
      });
    },
    async loadMoreSubComment(comment) {
      /**
       * 获取更多二级评论
       * @type {boolean}
       */
      this.commentParams.currentLoading = true;
      this.secPageNumber++;
      await this.getSecondList(comment);
    },
    reply(item, parentItem) {
      /**
       * 二级评论输入框显示事件
       */
      if (parentItem != null) {
        this.commentParams.currentFirstItem = parentItem;
        this.commentParams.rid = item.id;
        this.commentParams.toUserID = item.userID;
        this.commentParams.cid = parentItem.id;
      } else {
        this.commentParams.currentFirstItem = item;
        this.commentParams.rid = "";
        this.commentParams.toUserID = "";
        this.commentParams.cid = item.id;
      }
      this.commentParams.objID = item.objID;
      this.commentParams.userID = item.userID;
      this.commentParams.level = 2;
      this.placeholder = `回复@${item.userName}`;
      this.$refs.commentInputBoxRef.focus();
    },
    tagJump(index, itemTag) {
      let tagInfo = {
        id: this.atlasData.tags[index],
        name: itemTag.name,
        isAcg: 1,
      };
      if (this.$route.path == "/tagMore") {
        this.$router.replace({
          path: "/tagMore",
          query: {
            tagData: this.encodeBase64(encodeURI(JSON.stringify(tagInfo))),
            kind: this.atlasData.kind,
            t: Date.now(),
          },
        });
      } else {
        this.$router.push({
          path: "/tagMore",
          query: {
            tagData: this.encodeBase64(encodeURI(JSON.stringify(tagInfo))),
            kind: this.atlasData.kind,
          },
        });
      }
    },
    // 转base64
    encodeBase64(str) {
      let base64Str = Buffer.from(str, "utf-8").toString("base64");
      return base64Str;
    },
    jumpDetails(item) {
      this.$router.push({
        path: "/commentDetails",
        query: {
          msgInfo: this.encodeBase64(encodeURI(JSON.stringify(item))),
        },
      });
      // this.commentClose();
    },
    // 点赞
    async likeComment(item) {
      let req = {
        objID: item.id,
        type: "comment",
      };
      this.$store.commit("app/SET_LOADING", true);
      try {
        let res = await this.$Api(
          item.isLike ? thumbsDownComment : thumbsUpComment,
          req
        );
        this.$store.commit("app/SET_LOADING", false);
        if (res.code === 200) {
          if (item.isLike) {
            item.likeCount = item.likeCount - 1 || 0;
          } else {
            item.likeCount = item.likeCount + 1;
          }
          item.isLike = !item.isLike;

          Toast(item.isLike ? "点赞成功" : "取消点赞");
        } else {
          Toast(res.tip || "操作失败，请稍后再试");
        }
      } catch (error) {
        this.$store.commit("app/SET_LOADING", false);
        Toast("操作失败，请稍后再试");
      }
    },
  },
  beforeDestroy() {
   window.removeEventListener("scroll", this.handleScroll, true);
   window.removeEventListener('touchstart', this.onTouchStart);
   window.removeEventListener('touchend', this.onTouchEnd);
  },

  // destroyed() {
  //   this.atlasData.historyIndex = this.activeIndex;
  //   addVideoHistory(this.atlasData);
  // },
};
</script>

<style lang="scss" scoped>
.novelDetailsPage {
  overflow-y: auto;
  height: calc(100% - 60px);
  background: #f1f1f1;
  overflow-x: hidden;
  position: relative;
  .tabBars {
    background: #ffffff;
    position: fixed;
    bottom: 0;
    //height: 60px;
    padding: 0 12px 34px 0;
    width: 100%;
    z-index: 999;
   .comments {
    height: 36px;
    display: flex;
    justify-content: space-between;
    padding-top: 13px;
    padding-right: 10px;
    color: rgb(153, 153, 153);
    font-size: 15px;
    .Inputs {
     border-radius: 31px;
     box-sizing: border-box;
     display: flex;
     align-items: center;

     /deep/ .van-field__body {
      background: rgba(245, 245, 245, 1);
      box-shadow: 2px 2px 4px 0px rgba(174, 174, 192, 0.2) inset;
      padding: 6px 17px;
      border-radius: 8px;
     }
    }
    .sendBtn {
     display: flex;
     align-items: center;
     img {
      margin-left: 12px;
      width: 40px;
      height: 40px;
     }
    }
   }
  }
  .headBarBg {
   background: #fff;
  }
  .head {
   //position: relative;
   height: 44px;
   // background: rgba(19, 15, 50, 0.86);
   width: 100%;
   position: absolute;
   top: 0;
   z-index: 9;

   .headBar {
    display: flex;
    justify-content: space-between;
    height: 44px;
    align-items: center;
    z-index: 999;
    margin-top: 2px;
    .leftIcon {
     padding: 0 16px;

     img {
      width: 24px;
      height: 24px;
     }
     .icon {
      width: 20px;
      height: 20px;
     }
    }
   }

   .btnGroup {
    // justify-content: flex-end;
    // margin-top: 78px;
    //padding-right: 12px;
    // width: 100%;

    .collectionBtn {
     margin-right: 12px;

     .collect {
      width: 24px;
      height: 24px;
     }

     img {
      width: 60px;
      height: 24px;
     }
    }
   }
  }

  .tabBar {
    background: rgb(240, 240, 244);
    position: absolute;
    bottom: 0;
    height: 60px;
    width: 100%;
    z-index: 2;

    .comments {
      height: 59px;
      display: flex;
      justify-content: space-between;
      padding: 19px 24px;
      color: rgb(153, 153, 153);
      //font-weight: bold;
      font-size: 15px;

      img {
        width: 40px;
        height: 40px;
      }
    }

    .placeholder {
      height: 34px;
    }
  }

  .content {
    height: 100%;
    //overflow-y: auto;
    position: relative;
  }

  .headBg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 500px;
    //-webkit-filter: blur(2px);
    //filter: blur(2px);
    //z-index: -1;
  }
  .headBg:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 60.22%, #F5F5F5 91.18%);
    z-index: 1;
  }

  .worksInfo {
    height: 500px;
    // background: #F1F1F1;
    // margin-top: -5px;
    position: relative;
    z-index: 2;

    .contentInfo {
      position: absolute;
      bottom: 10px;
      left: 0;
      //display: grid;
      //grid-template-columns: repeat(2, 1fr);
      //gap: 12px;
    }
    .previewImg {
      width: 128px;
      height: 170px;
      border-radius: 8px;
      // /deep/ .van-image {
      //     border-radius: 6px;
      // }
      /deep/ .van-image__img {
        border-radius: 8px;
      }
    }

    .worksContent {
      padding: 12px 10px 0;

      .worksTitle {
        font-size: 18px;
        color: rgb(0, 0, 0);
        font-weight: bold;
      }

      .worksTag {
        margin: 8px 0 6px 0;
        font-size: 14px;
        color: #333333;
        display: flex;
        align-items: center;
        text-align: center;
        flex-wrap: wrap;

       .tagItem {
        max-width: 69px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: rgba(176, 159, 217, 1);
        font-weight: 400;
        margin-right: 5px;
        font-size: 12px;
        .tagPrefix {
         width: 14px;
         height: 14px;
         vertical-align: middle;
        }
        span {
         vertical-align: middle;
         margin-left: 4px;
        }
       }
      }
    }

    .worksDetails {
      width: 100%;
      margin-top: 10px;
      box-sizing: border-box;
      font-size: 12px;
      font-weight: 500;
      color: #333333;

      /deep/ .overEllipsis {
        font-size: 12px;
      }

      /deep/ .text-btn {
        font-size: 14px;
        margin: 10px 0 0 0;
        color: #999999;
      }

      overflow: hidden;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      text-overflow: ellipsis;
      word-wrap: break-word;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      // display: -webkit-box;
      // -webkit-box-orient: vertical;
    }

    .worksFullBtn {
      margin-top: 12px;
      color: rgb(153, 153, 153);
      font-size: 14px;
    }
    .showAll {
     position: absolute;
     right: 10px;
     font-size: 12px;
     bottom: 12px;
     color: #814CFF;
     background: #F5F5F5;
    }
  }

  .directory {
    //height: 97px;
    //margin-top: 12px;

    .directoryBar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 25px;
      padding: 0 12px;
      color: rgb(0, 0, 0);

      .directoryBarTitle {
        font-size: 14px;
        font-weight: 900;
        color: #333333;
      }

      .directoryBtn {
        font-size: 12px;
        color: #777777;

        img {
          width: 6.9px;
          height: 8.6px;
          margin-left: 8.1px;
        }
      }
    }
    .anthology {
      display: flex;
      margin-top: 12px;
      // overflow-x: auto;
      overflow-y: hidden;
      padding-right: 12px;

      .activeItem {
       background-image: linear-gradient(#F5F5F5, #F5F5F5),
       linear-gradient(180deg, rgba(255, 255, 255, 1) 100%, rgba(255, 255, 255, 1) 100%) !important;
       div:nth-child(2) {
        background: #fff;
       }
      }

      .anthologyItem {
        //-webkit-flex: 1 0 auto;
        margin-left: 12px;
        width: 50px;
        height: 67px;
        line-height: 26px;
        text-align: center;
        border: double 3px transparent;
        border-radius: 8px;
        background-image: linear-gradient(white, white),
        linear-gradient(135deg, rgba(0, 0, 0, 0.05) 0%, rgba(216, 216, 216, 0.05) 100%);
        background-origin: border-box;
        background-clip: content-box, border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .anthologyTxt {
         display: flex;
         border-radius: 8px;
         justify-content: center;
         align-items: center;
         margin-bottom: 2px;
         span {
          font-size: 18px;
          font-weight: 900;
          color: rgba(176, 159, 217, 1);
         }
        }

        .vipLabel {
          display: flex;
          justify-content: center;
          align-items: center;
          background: #F5F5F5;
          border-radius: 8px;
          font-size: 10px;
          width: 31px;
          height: 19px;
          img {
           width: 18px;
           height: 8px;
          }

          //span {
          //  //background-image: linear-gradient(to right, #f3cd64, #fde39c);
          //  background: #F5F5F5;
          //  border-radius: 8px;
          //  font-size: 10px;
          //  width: 31px;
          //  height: 19px;
          //  color: #814CFF;
          //  display: flex;
          //  justify-content: center;
          //  align-items: center;
          //}
        }

        .goldenLabel {
         display: flex;
         justify-content: center;
         align-items: center;
         background: #F5F5F5;
         border-radius: 8px;
         font-size: 10px;
         width: 31px;
         height: 19px;
         img {
          width: 18px;
          height: 8px;
         }
          //display: flex;
          //justify-content: flex-end;
          //width: 60px;
          //
          //span {
          //  background-image: linear-gradient(
          //    to right,
          //    rgb(255, 57, 87),
          //    rgb(255, 89, 34)
          //  );
          //  font-size: 6px;
          //  width: 24px;
          //  height: 12px;
          //  color: rgb(255, 255, 255);
          //  display: flex;
          //  justify-content: center;
          //  align-items: center;
          //  border-radius: 3px;
          //}
        }

        .freeLabel {
          display: flex;
          justify-content: center;
          align-items: center;
          background: #F5F5F5;
          border-radius: 8px;
          font-size: 10px;
          width: 31px;
          height: 19px;
          img {
           width: 18px;
           height: 8px;
          }
          //display: flex;
          //justify-content: flex-end;
          //width: 60px;
          //
          //span {
          //  background-image: linear-gradient(
          //    to right,
          //    rgb(87, 139, 255),
          //    rgb(51, 255, 227)
          //  );
          //  font-size: 6px;
          //  width: 24px;
          //  height: 12px;
          //  color: rgb(255, 255, 255);
          //  display: flex;
          //  justify-content: center;
          //  align-items: center;
          //  border-radius: 3px;
          //}
        }

        .activePlay {
          position: relative;
          top: -22px;
          left: 7px;

          img {
            width: 13px;
            height: 8px;
          }
        }
      }
    }

    .startReading {
      margin-top: 31px;
      display: flex;
      justify-content: center;
      align-items: center;

      .startReadingBtn {
        width: 333px;
        height: 56px;
        border-radius: 22px;
        background: #F0F0F3;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        color: rgba(176, 159, 217, 1);
        font-weight: 900;
        box-shadow: 5px 5px 20px 0px rgba(174, 174, 192, 0.5);
      }
    }
  }
  .lines {
    width: 92%;
    height: 1px;
    margin: 26px 16px 0 16px;
    background: #e6e6e6;
  }
  .recommendVideo {
    margin-top: 36px;
    padding: 0 12px;

    .recommendTitle {
      font-size: 18px;
      color: rgb(0, 0, 0);
      font-weight: bold;
      margin-bottom: 9px;
    }
  }

  .introductionContent {
    padding: 0 12px;

    .introductionBar {
      margin-top: 18px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        font-size: 16px;
        color: rgba(51, 51, 51, 1);
        font-weight: 900;
      }

      .btn {
        display: flex;
        align-items: center;

        img {
          width: 14.8px;
          height: 14.8px;
        }
      }
    }

    .introductionTitle {
      font-size: 16px;
      color: rgb(0, 0, 0);
      font-weight: 900;
      margin-top: 16px;
    }

    .introductionTag {
     margin: 8px 0 8px 0;
     font-size: 14px;
     color: #333333;
     display: flex;
     align-items: center;
     text-align: center;
     flex-wrap: wrap;

     .tagItem {
      max-width: 69px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: rgba(176, 159, 217, 1);
      font-weight: 400;
      margin-right: 5px;
      font-size: 12px;
      .tagPrefix {
       width: 14px;
       height: 14px;
       vertical-align: middle;
      }
      span {
       vertical-align: middle;
       margin-left: 4px;
      }
     }
    }

    .introductionDetails {
      color: rgb(0, 0, 0);
      font-size: 14px;
    }
  }

  .directoryContent {
   overflow: hidden;
    .directoryBar {
      padding: 0 12px;
      margin-top: 18px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        font-size: 18px;
        color: rgb(0, 0, 0);
        font-weight: bold;
      }

      .btn {
        display: flex;
        align-items: center;

        img {
          width: 14.8px;
          height: 14.8px;
        }
      }
    }

    .anthologyContentBox {
     height: 100%;

     .anthologyClassifyBox {
      padding: 0 16px;
      margin-top: 8px;
      display: flex;
      flex-flow: wrap;

      .anthologyClassifyItem {
       background: #fff;
       padding: 8px 30px;
       border-radius: 28px;
       margin-right: 11px;
       margin-top: 8px;
       border: 1px solid #fff;
       box-sizing: border-box;
      }

      .anthologyClassifyActiveItem {
       background: rgba(245, 245, 245, 1);
       border: 2px solid rgba(255, 255, 255, 1);
      }
     }
     .anthologyContent {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      margin-top: 24px;
      margin-left: 8px;

      .activeItem {
       background-image: linear-gradient(#F5F5F5, #F5F5F5),
       linear-gradient(180deg, rgba(255, 255, 255, 1) 100%, rgba(255, 255, 255, 1) 100%) !important;
       div:nth-child(2) {
        background: #fff;
       }
      }

      .anthologyItem {
       //-webkit-flex: 1 0 auto;
       margin-bottom: 15px;
       width: 50px;
       height: 67px;
       line-height: 26px;
       text-align: center;
       border: double 3px transparent;
       border-radius: 8px;
       background-image: linear-gradient(white, white),
       linear-gradient(135deg, rgba(0, 0, 0, 0.05) 0%, rgba(216, 216, 216, 0.05) 100%);
       background-origin: border-box;
       background-clip: content-box, border-box;
       display: flex;
       flex-direction: column;
       justify-content: center;
       align-items: center;

       .anthologyItem {
        margin-bottom: 0;
       }

       .anthologyTxt {
        display: flex;
        border-radius: 8px;
        justify-content: center;
        align-items: center;
        margin-bottom: 2px;
        span {
         font-size: 18px;
         font-weight: 900;
         color: rgba(176, 159, 217, 1);
        }
       }

       .vipLabel {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #F5F5F5;
        border-radius: 8px;
        font-size: 10px;
        width: 31px;
        height: 19px;
        img {
         width: 18px;
         height: 8px;
        }
       }

       .goldenLabel {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #F5F5F5;
        border-radius: 8px;
        font-size: 10px;
        width: 31px;
        height: 19px;
        img {
         width: 18px;
         height: 8px;
        }

       }

       .freeLabel {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #F5F5F5;
        border-radius: 8px;
        font-size: 10px;
        width: 31px;
        height: 19px;
        img {
         width: 18px;
         height: 8px;
        }
       }

       .activePlay {
        position: relative;
        top: -22px;
        left: 7px;

        img {
         width: 13px;
         height: 8px;
        }
       }
      }
     }
    }
  }

  .separate {
    background: rgb(230, 230, 230);
    height: 1px;
  }

  .comments {
    .commentsContent {
      //height: calc(100vh - 211px - 51px - 92px);
      color: rgb(0, 0, 0);
      //height: 100%;
      //overflow-y: auto;

      .commentsHead {
        font-size: 16px;
        font-weight: 500;
        margin-top: 18px;
        padding-left: 12px;
        .comTotle {
          padding-left: 6px;
          font-size: 12px;
          font-weight: 400;
          color: #94d6da;
        }
      }

     .qiuckSearchBox {
       color: #333333;
       font-weight: 600;
       font-size: 14px;
       margin: 3px 10px 0 10px;
       white-space: nowrap;
       position: relative;

       /deep/ .van-notice-bar__content {
        color: #F92B6C !important;
        font-weight: 600 !important;
       }

      /deep/ .van-notice-bar {
        width: 100%;
        padding: 0;
       }

       .qiuckSearchIcon {
        height: 10px;
        width: 10px;
        position: absolute;
        right: -10px;
        top: 2px;
       }
      }

      .commentsContentList {
        padding: 18px 12px;
        .commentBox {
          margin-bottom: 18px;
          margin-top: 18px;
          border-bottom: solid rgb(229, 229, 229) 1px;

          .commentInfo {
            display: flex;
            width: 100%;

            .godMsg {
              position: absolute;
              right: 55.8px;
              width: 60.2px;
              height: 60.2px;
              z-index: 10;
            }

            .avatar {
              height: 40px;
              width: 40px;
              border-radius: 50%;
              margin-right: 12px;
              flex-shrink: 0;
            }

            .info {
              width: calc(100vw - 77px);
              flex-shrink: 0;
              color: rgb(51, 51, 51);

              .name {
                color: #333333;
                font-size: 14px;
                display: flex;
                align-items: center;

                .hotImg {
                 width: 44px;
                 height: 22px;
                 vertical-align: middle;
                }

                span {
                  margin-right: 4px;
                  //font-weight: bold;
                }

                svg {
                  width: 10px;
                  height: 10px;
                }
              }

              .msgContent {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .zanBox {
                  position: absolute;
                  right: 0;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  z-index: 20;

                  .zanIcon {
                    height: 22px;
                    width: 22px;
                    margin-bottom: 5px;
                  }

                  .zanNumber {
                    font-size: 12px;
                  }
                }
              }

              .text {
                //width: 300px;
                //text-overflow: ellipsis;
                //word-wrap: break-word;
                //white-space: pre-wrap;

                //width: 100%;
                //overflow: hidden;
                white-space: pre-wrap;
                word-wrap: break-word;
                text-overflow: ellipsis;

                font-size: 14px;
                //margin: 4px 0 7px;
                margin-top: 5px;
                color: rgb(51, 51, 51);
              }

              .address {
                //font-size: 10px;
                //color: rgb(153, 153, 153);
                margin-top: 8px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .createdAt {
                  font-size: 12px;
                  color: rgb(153, 153, 153);
                }

                //.btn {
                //  font-size: 10px;
                //  color: rgb(255, 255, 255);
                //  margin-left: 6px;
                //}

                .btnBox {
                  display: flex;
                  align-items: center;

                  .icon {
                    width: 20px;
                    height: 20px;
                  }

                  .community_info {
                    width: 14px;
                    height: 14px;
                  }

                  .like {
                    margin-left: 18px;
                  }
                }
              }
            }

            .childInfo {
              width: calc(100vw - 129px);
              flex-shrink: 0;
              color: rgb(102, 102, 102);

              .name {
                color: rgb(153, 153, 153);
                font-size: 12px;
                display: flex;
                align-items: center;

                span {
                  margin-right: 4px;
                }

                svg {
                  width: 10px;
                  height: 10px;
                }

                .rightCommIcon {
                  width: 24px;
                  height: 18px;
                  margin-left: 2px;
                  margin-right: 3px;
                }
              }

              .msgContent {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .zanBox {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  z-index: 20;

                  .zanIcon {
                    height: 22px;
                    width: 22px;
                    margin-bottom: 5px;
                  }

                  .zanNumber {
                    font-size: 12px;
                  }
                }
              }

              .text {
                //width: 300px;
                //text-overflow: ellipsis;
                //word-wrap: break-word;
                //white-space: pre-wrap;

                //width: 100%;
                //overflow: hidden;
                white-space: pre-wrap;
                word-wrap: break-word;
                text-overflow: ellipsis;

                font-size: 14px;
                //margin: 4px 0 7px;
                margin-top: 5px;
                color: rgb(51, 51, 51);
              }

              .address {
                font-size: 10px;
                color: rgb(153, 153, 153);
                margin-top: 8px;

                .btn {
                  font-size: 10px;
                  color: rgb(255, 255, 255);
                  margin-left: 6px;
                }
              }
            }
          }

          .expand-box {
            font-size: 12px;
            color: rgb(153, 153, 153);
            font-weight: 300;
            margin: 8px 0;
            display: flex;
            justify-content: space-around;
            align-items: center;

            > svg {
              height: 12px;
              width: 12px;
              margin-left: 5px;
            }
          }

          // 旋转180度
          .rotate-target {
            transform: rotate(180deg);
            transition: transform 0.3s;
          }

          // 旋转0度
          .rotate-burden-target {
            transform: rotate(0deg);
            transition: transform 0.3s;
          }
        }
      }
    }
  }

  .inputPop {
    .inputBox {
      height: 59px;
      padding: 0 12px;
      box-sizing: border-box;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .newInput {
        width: 273px;
        height: 31px;
        border-radius: 31px;
        padding: 0 12px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        background: #e6e6e6;

        /deep/ .van-field__control {
          font-size: 12px;
          color: #000;
        }

        ::placeholder {
          color: #999;
        }
      }

      .sendBtn {
        width: 66px;
        height: 33px;
        border-radius: 33px;
        font-size: 15px;
        color: #fff;
        background: #757494;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .activeBtn {
        color: #fff;
        background: #7145e7;
      }
    }
  }

  .nodata {
    height: calc(100vh - 103px);
    // height: calc(100% - 92px);
    width: 100%;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
